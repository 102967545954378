//
import GGoogleService from '@/services/api/General/GGoogle'

//
export default {
  name: 'v-view-google-redirect',
  data() {
    return {
      busy: {
        google: false,
      },
      gGoogle: {},
    }
  },
  methods: {

    /**
     * 
     */
    getGoogle() {
      this.gGoogle      = GGoogleService.toAccess()
      this.gGoogle.auth = this.$route.query.state
      this.gGoogle.code = this.$route.query.code
    },

    /**
     * 
     */
    handleError(payload) {
      GGoogleService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    handleFormReset() {
      this.$router.push('/')
    },

    /**
     * 
     */
    async handleFormSubmit() {
      this.busy.google = true
      await GGoogleService.access(this.gGoogle).then(this.handleSuccess).catch(this.handleError)
      this.busy.google = false
    },

    /**
     * 
     */
    handleSuccess(response) {
      GGoogleService.translate({ response }).then(this.$toast.error)      
    },
  },
  mounted() {
    this.getGoogle()
    this.handleFormSubmit()
  },
}